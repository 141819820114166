import React, { Component } from "react";
import Helmet from "react-helmet";
import config from "../../data/SiteConfig";

import Layout from "../components/layout"
import SectionTitle from "../components/SectionTitle/SectionTitle";
import Content from "../components/Content/Content";

import FullWidthImage2  from "../components/FullWidthImage2/FullWidthImage2";
// import img from "../imgs/american-public-power-association-419672-unsplash.jpg";
const img = 'https://res.cloudinary.com/downtowndev/image/upload/q_auto,f_auto/american-public-power-association-419672-unsplash_c7cpaa.jpg';

class AboutPage extends Component {
  render() {
    return (
      <Layout location={this.props.location}>
      <div className="about-container underNavbar">
        <Helmet htmlAttributes={{ lang: 'en' }} title={`Careers | ${config.siteTitle}`} />
        <FullWidthImage2 title={'Careers'} image={img} />
          <Content className='page-content'>
            <h2>We're Hiring!</h2>
            <p>SES is looking to build a multi-disciplinary team required to implement complex projects from concept through engineering.  We are looking for people who demonstrate a quest for excellence with a focus on our client needs.</p>

            <p>Please send your resume and project history to info@sverdrupservices.com. Of course, we will maintain your confidentiality.</p>

            <p>Thanks!</p>
          </Content>
      </div>
      </Layout>
    );
  }
}

export default AboutPage;
